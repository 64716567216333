.track-input-width{
    width: 70%!important;
}

.home-track {
    padding-left: 10%;
}
.magenta-clr{
    background-color: #e6007e!important;
}
.clr-primary{
    color: #322783 !important;
    font-weight: bold!important;
}
.text-right{
    text-align: right!important;
}
.tracking-clr{
    padding: 3%;
    background: #e7e7e7 !important;
    text-align: left;
}
.tracking-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tracking-content h5 {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tracking-content input[type="text"] {
    border: none;
    background-color: transparent;
    width: 70%;
}
.clr-whatsapp{
    color: #128c7e;
    font-weight: bold;
    font-size: 30px;
}

.modal-content{
    /*background-color: #42469d;*/
    /*border-color: #42469d;*/
    background-color: grey;
    border-color: grey;
    border-radius: 1rem;
}
@media (min-width: 576px){
    .modal-dialog {
        max-width: 750px;
        margin: 1.75rem auto;
    }
}
.show{
    padding: 0;
}
.modal-header{
    border-bottom: none;
    text-align: center;
}
.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem 0;
    color: #fff;
}
:-moz-any-link:focus {
    outline: none;
}
.modal-title{
    line-height: 3rem;
    font-size: 40px!important;
}
.modal-body{
    padding: 1rem;
}
#progressbar {
    margin-bottom: 3vh;
    overflow: hidden;
    color: white;
    padding-left: 0px;
    margin-top: 3vh
}

#progressbar li {
    list-style-type: none;
    font-size: 0.8rem;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    color: white;
}

#progressbar #step1:before {
    content: "";
    color: white;
    width: 20px;
    height: 20px;
    margin-left: 0px !important;
}

#progressbar #step2:before {
    content: "";
    color: #fff;
    width: 20px;
    height: 20px;
    margin-left: 32%;
}

#progressbar #step3:before {
    content: "";
    color: #fff;
    width: 20px;
    height: 20px;
    margin-right: 32% ;
}

#progressbar #step4:before {
    content: "";
    color: rgb(151, 149, 149, 0.651);
    width: 20px;
    height: 20px;
    margin-right: 0px !important;
}

#progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: rgb(151, 149, 149);
    border-radius: 50%;
    margin: auto;
    z-index: -1;
    margin-bottom: 1vh;
}

#progressbar li:after {
    content: '';
    height: 3px;
    background: rgb(151, 149, 149, 0.651);
    position: absolute;
    left: 0%;
    right: 0%;
    margin-bottom: 2vh;
    top: 8px;
    z-index: 1;
}
.progress-track{
    padding: 0 8%;
}
#progressbar li:nth-child(2):after {
    margin-right: auto;
}

#progressbar li:nth-child(1):after {
    margin: auto;
}

#progressbar li:nth-child(3):after {
    float: left;
    width: 68%;
}
#progressbar li:nth-child(4):after {
    margin-left: auto;
    width: 132%;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: white;
}

#three{
    font-size: 1.2rem;
}
@media (max-width: 767px){
    #three{
        font-size: 1rem;
    }
}
.details{
    padding: 2rem;
    font-size: 1.4rem;
    line-height: 3.5rem;
}

@media (max-width: 767px){
    .details {
        padding: 2rem 0;
        font-size: 1rem;
        line-height: 2.5rem;
    }
}
.d-table{
    width: 100%;
}
.d-table-row{
    width: 100%;
}
.d-table-cell{
    padding-left: 3rem;
}
@media (max-width: 767px){
    .d-table-cell{
        padding-left: 1rem;
    }
}

@media (max-width: 1024px){
    .d-table-cell{
        font-size: 16px;
        padding-left: 0rem;
    }
    .modal-title {
        line-height: 3rem;
        font-size: 20px !important;
    }
}
@media (max-width: 375px){
    .d-table-cell{
        font-size: 10px;
        padding-left: 0rem;
    }
    .modal-title {
        line-height: 3rem;
        font-size: 18px !important;
    }

}

@media (min-width: 995px){
    .d-table-cell{
        font-size: 14px;
        padding-left: 0rem;
    }
    .modal-title {
        line-height: 3rem;
        font-size: 18px !important;
    }

}

@media (max-width: 455px){
    .d-table-cell{
        font-size: 8px;
        padding-left: 0rem;
    }
    .modal-title {
        line-height: 3rem;
        font-size: 18px !important;
    }

}

@media (max-width: 430px){
    .header-text{
        font-size: 13px!important;
        font-weight: bold;
        color: #322783
    }
}

@media (min-width: 370px){
    .track-input-width {
        width: 90% !important;
    }
}


.header-text{
    font-size: 21px;
    font-weight: bold;
    color: #322783
}


.track-btn{
    width: 154px;
    background-color: orange;
}

.bg-orange{
    background-color: orange!important;
}


.contact-font-size{
    font-size: 20px;
}

.bg-white{
    background-color: #fff!important;
}

.clr-orange{
    color: orange!important;
    font-weight: bold;
}